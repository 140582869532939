export default {
  heading: "Régimes de raccordement",
  FULL_SALE: {
    title: "Vente en totalité",
    description:
      "L’intégralité de la production est injectée dans le réseau et vendue au tarif d’achat.",
  },
  FULL_SELF_CONSUMPTION: {
    title: "Autoconsommation (Totale)",
    description:
      "La totalité de la production est consommée sur place sans injecter d’électricité dans le réseau.",
  },
  PARTIAL_SELF_CONSUMPTION: {
    title: "Autoconsommation (Injection du surplus)",
    description:
      "La production est autoconsommée instantanément : l'excédent est injecté dans le réseau.",
  },
}
